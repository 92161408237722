import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BasePage from '@/components/BasePage';
import BasicNavLink from '@/Handlers/BasicNavLink';
import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Translation Higher Order Component
import { translate } from 'react-switch-lang';
const items = [
  {
    src: '/images/picture1',
    altText: 'pictures.main1',
  },
  {
    src: '/images/picture2',
    altText: 'pictures.main2',
  },
  {
    src: '/images/picture3',
    altText: 'pictures.main3',
  },
  {
    src: '/images/picture4',
    altText: 'pictures.main4',
  },
  {
    src: '/images/picture5',
    altText: 'pictures.main5',
  },
];

const Main = ({ t, showLowResImages }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className='carousel-item'
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <LazyLoadImage
          width='100%'
          // height="1600"
          className='main-page_images'
          alt={t(item.altText)}
          placeholderSrc={showLowResImages ? `${item.src}_low.webp` : null}
          effect='blur'
          src={`${item.src}.webp`}
        />
      </CarouselItem>
    );
  });

  return (
    //React.Fragment is used instead of <div> or <> to hide it from source
    <React.Fragment>
      {/* Base Page - Shared component with Container */}
      <BasePage id='main-page' className='main-page'>
        <Row>
          <Carousel
            className='w-100'
            activeIndex={activeIndex}
            next={next}
            previous={previous}
          >
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
              direction='prev'
              directionText='Previous'
              onClickHandler={previous}
            />
            <CarouselControl
              direction='next'
              directionText='Next'
              onClickHandler={next}
            />
          </Carousel>
          <Col className='main-page_text absolute' sm='10' md='12' lg='11'>
            <h1 className='font-weight-bold'>{t('main.title')}</h1>
            <p className='page_subtext text-uppercase main-page_subtext'>
              {' '}
              {t('main.subtitle')}
            </p>
          </Col>
          <div className='main-page_covid-text page_subtext country_links d-none d-md-block absolute'>
            <div className='text-success text-strong font-weight-bold text-center main_no-vaccine'>
              {t('main.call_us')}
            </div>
            {/* <BasicNavLink route={`/covid`} title={t('main.covid')} /> */}
            <BasicNavLink
              height='line-height booking_button'
              route='/travel-test'
              title={`COVID-19${t('navbar.covid')}`}
            />
            {/* <BasicNavLink
							height="line-height booking_button d-block"
							route="/covid-test"
							title={`Coronavirus Test`}
						/> */}
          </div>
        </Row>
      </BasePage>
    </React.Fragment>
  );
};

export default translate(Main);
